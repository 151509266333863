import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { ReportsService } from 'src/app/services/reports.service';

@Component({
  selector: 'app-archive-report-popup',
  templateUrl: './archive-report-popup.component.html',
  styleUrls: ['./archive-report-popup.component.scss']
})
export class ArchiveReportPopupComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ArchiveReportPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, 
    private reportService: ReportsService,
     private toastr: ToastrService) { }
  subject=''
  additional_info=''
  ngOnInit(): void {
    this.additional_info=this.data.addition_info
    this.subject = `Requesting Archive Report of ${this.data.reportType} from ${this.data.startDate} to ${this.data.endDate} `
  }

  submit() {
    const payload = {
      "startDate": this.data.startDate,
      "endDate": this.data.endDate,
      "report_type": this.data.reportType,
      "additional_info": this.additional_info,
      "subject": this.subject
    }
    this.reportService.getArchivedReports(payload).subscribe(res => {
      // console.log(res)
      if(res){
        this.toastr.success(res.message)
        this.dialogRef.close()
      }
    }, err => {
      console.log(err)
      this.toastr.error(err.error.message)
    })
  }

}
