import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup
  rememberMe = true
  isLoading = true
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private toastr: ToastrService,
    private authService: AuthenticationService) { }

  ngOnInit(): void {
    localStorage.removeItem('issacUser');
    // sessionStorage.removeItem('issacUser');
    this.loginForm = this.formBuilder.group({
      username: [null, Validators.required],
      password: [null, Validators.required],
      rememberMe: false
    });
    this.isLoading = false
  }

  login() {
    this.isLoading = true
    let y = window.screen.height
      , x = window.screen.width
    let payload = { ...this.loginForm.value, x, y }
    delete payload.rememberMe
    this.authService.getIp().subscribe(res => { }, err => {
      payload.ipAddress = err.error.text
      this.authService.login(payload).subscribe(res => {
        if (res && res.statusCode == 200) {
          let data = res.data
          if (data && data.location && data.location.modules) {
            data.location.modules = data.location.modules.map(e => e == 'Project' ? 'Projects' : e)
          }
          // if (this.loginForm.value.rememberMe) {
          //   localStorage.setItem('issacUser', JSON.stringify(data));
          // } else {
          //   sessionStorage.setItem('issacUser', JSON.stringify(data));
          // }
          localStorage.setItem('issacUser', JSON.stringify(data));
          this.isLoading = false

          let returnUrl = localStorage.getItem('redirectUrl');
          if (returnUrl) {
            this.router.navigateByUrl(returnUrl);
            localStorage.removeItem("redirectUrl");
          } else {
            this.router.navigate(['/dashboard']);
          }
        }
      }, err => {
        this.isLoading = false
        this.toastr.error(err.error.message);
        console.log(err)
      })
    })


  }
}
