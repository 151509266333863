import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
@Injectable({
    providedIn: 'root'
})
export class CustomFilterService {

    constructor(private http: HttpClient) { }

    getFilterdData(payload) {
        let url = environment.SERVER_BASEURL + `filter/value`
        return this.http.post<any>(url, payload);
    }

    getFilters(payload) {
        return this.http.post<any>(environment.SERVER_BASEURL + `filter/criteria`,payload)
    }
}
