import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ArchiveReportPopupComponent } from 'src/app/modules/reports/archive-report-popup/archive-report-popup.component';
import { ReservationsListComponent } from 'src/app/modules/reservations/dashboard/reservations-list/reservations-list.component';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { CustomFilterService } from 'src/app/services/custom-filter.service';

class pageEvent {
    page: number;
    limit: number;
}

@Component({
    selector: 'custom-filter',
    templateUrl: './custom-filter.component.html',
    styleUrls: ['./custom-filter.component.scss']
})
export class CustomFilterComponent implements OnInit {
    @ViewChild('f') form: NgForm;
    @Input() filterList
    @Input() type: string
    @Input() collection: [];
    @Input() columnsCode: []
    @Input() datecolumncode: string
    @Input() page
    @Input() limit
    @Input() xlseData
    @Input() lookups
    @Input() filterLookups?
    @Output() filteredData = new EventEmitter<String[]>()
    @Output() filteredDataPage = new EventEmitter<pageEvent>()
    @Output() exportExcel = new EventEmitter<String[]>()
    @Output() exportPdf = new EventEmitter<any>()
    // @ViewChild(ReservationsListComponent, {static: false} ) reservationlist: ReservationsListComponent ;

    customFilterForm: FormGroup
    typeFilter = []
    customFilterValue = ''
    loading = false
    selectFilter
    selectValue
    maxDate = moment()
    data = []
    loadingFilter = false
    showArchiveButton: boolean = false
    constructor(
        private formBuilder: FormBuilder,
        private cfService: CustomFilterService,
        private toastr: ToastrService,
        private dialog: MatDialog,
        private authService: AuthenticationService) {
        this.alwaysShowCalendars = true;
    }

    ngOnInit(): void {
        this.customFilterForm = this.formBuilder.group({
            FilterType: ['', Validators.required],
            FilterValue: [{ value: '', disabled: true }, Validators.required],
            dateRange: [{ startDate: moment().subtract(29, 'days'), endDate: moment() }, Validators.required],
        })
        this.selectFilter = this.filterList;
        if (this.selectFilter.length == 1) {
            this.customFilterForm.get('FilterType').patchValue(this.selectFilter[0])
            this.getFilter(this.selectFilter[0])
        }
    }

    alwaysShowCalendars: boolean;
    ranges: any = {
        'Today': [moment(), moment()],
        'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
        'Last 7 Days': [moment().subtract(6, 'days'), moment()],
        'Last 30 Days': [moment().subtract(29, 'days'), moment()],
        'Next 30 Days': [ moment(), moment().add(29, 'days')],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
        'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
        'Last 3 Month': [moment().subtract(3, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
        'Last 6 Month': [moment().subtract(6, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
        'This Year': [moment().startOf('year'), moment()],
        'Last Year': [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')],
        'Last Three Years': [moment().subtract(1095.5, 'days'), moment()]
    }

    getFilter(filter) {
        this.loadingFilter = true
        this.customFilterForm.controls['FilterValue'].disable()
        this.customFilterForm.controls['FilterValue'].setValue('')
        let columnCode = this.columnsCode.filter(column => {
            return column['name'] === filter
        })[0]
        let payload = {
            "loc_id": this.authService.currentUserValue.loc_id,
            "collection": this.collection,
            "filter": columnCode['code']
        }
        if(this.collection.toString() == "locations")
        delete payload.loc_id
        if ((this.filterLookups && this.filterLookups.length > 0) && (this.filterLookups.map(e => e.from).includes(columnCode['code']) || this.filterLookups.map(e => e.from).includes(columnCode['lookupCode']))) {
            payload['from'] = this.filterLookups.filter(e => (e.from === columnCode['code'] || e.from === columnCode['lookupCode']))[0].from//this.filterLookups.from
            payload['foreignField'] = this.filterLookups.filter(e => (e.from === columnCode['code'] || e.from === columnCode['lookupCode']))[0].foreignField//this.filterLookups.foreignField
            payload['project'] = this.filterLookups.filter(e => (e.from === columnCode['code'] || e.from === columnCode['lookupCode']))[0].project//this.filterLookups.project
            // payload['Payment'] = this.filterLookups.filter(e => (e.from === columnCode['code'] || e.from === columnCode['lookupCode']))[0].project//this.filterLookups.project
        }
        this.cfService.getFilters(payload).subscribe(res => {
            if (res.data) {
                this.typeFilter = (this.filterLookups && this.filterLookups.length > 0) && (this.filterLookups.map(e => e.from).includes(columnCode['code']) || this.filterLookups.map(e => e.from).includes(columnCode['lookupCode'])) ?
                    res.data.filter(e => e && e !== 'undefined').map(e => Object.keys(this.filterLookups.filter(e => (e.from === columnCode['code'] || e.from === columnCode['lookupCode']))[0].project).map(key => e[key]).join(' - '))
                    : res.data.filter(e => e && e !== 'undefined')

                // this.typeFilter.push('All')
                this.selectValue = this.typeFilter.sort()
                this.loadingFilter = false
                this.customFilterForm.controls['FilterValue'].enable()
            } else {
                this.toastr.error('No Filters Found', 'Error!')
                this.loadingFilter = false
            }
        }, err => {
            this.toastr.error(err.message)
            this.loadingFilter = false
        })
        this.customFilterValue = columnCode['name']
    }

    getFilterValue(value) {
        if (this.type == 'Reservations Report') {
            let pageLimit: pageEvent = new pageEvent
            pageLimit.page = 1;
            pageLimit.limit = 20;
            this.filteredDataPage.emit(pageLimit)
            // this.reservationlist.customFilterValue()
        }
    }

    applyFilterU(searchValue) {
        this.selectFilter = this.filterList
        this.selectFilter = this.filterList.filter(option => option.toLowerCase().includes(searchValue.toLowerCase()));
    }

    applyKeyUpFilter(str) {
        this.selectValue = this.typeFilter
        this.selectValue = this.typeFilter.filter(option => option.toLowerCase().includes(str.toLowerCase()))
    }

    clear() {
        this.selectValue = this.typeFilter
        this.selectFilter = this.filterList;
    }

    getColumnCode4Filter(filter) {
        return this.columnsCode.filter(column => column['name'] === filter)[0]['code']
    }

    reservationFilter(val) {
        // console.log(val)
        this.page = val.page
        this.limit = val.limit
        this.applyCustomFilter('All')
    }

    applyCustomFilter(e) {
        if (this.form.valid) {

            let columnCode = this.columnsCode.filter(column => {
                return column['name'] === this.customFilterForm.get('FilterType').value
            })[0]
            this.loading = true
            const keyValue = this.getColumnCode4Filter(this.customFilterForm.get('FilterType').value)
            const collection = this.collection.toString()
            const value = (this.filterLookups && this.filterLookups.length > 0) && (this.filterLookups.map(e => e.from).includes(columnCode['code']) || this.filterLookups.map(e => e.from).includes(columnCode['lookupCode'])) ? this.customFilterForm.get('FilterValue').value.split(' ')[0]
                : this.customFilterForm.get('FilterValue').value
            let payload = {
                loc_id: this.authService.currentUserValue.loc_id,
                collection: this.collection,
                keyValue,
                value: keyValue == 'projectType' ? parseInt(value) : keyValue == 'user_id' ? parseInt(value) : value,
                keyFoundInObj: this.customFilterValue,
                start_date: moment(this.customFilterForm.value.dateRange.startDate).format('L'),
                end_date: collection == "payments" ? moment(new Date(new Date(this.customFilterForm.value.dateRange.endDate).setDate(new Date(this.customFilterForm.value.dateRange.endDate).getDate() + 1))).format('L') : moment(this.customFilterForm.value.dateRange.endDate).format('L'),
                datecolumncode: this.datecolumncode
            }
            if(this.collection.toString() == "locations")
            delete payload.loc_id
            if (this.lookups) {
                payload['lookups'] = this.lookups
            }
            if (this.type == 'Reservations Report') {
                payload['sortOrder'] = -1
                payload['sortby'] = 'startOrderDate'
                payload['page'] = this.page
                payload['limit'] = this.limit
            }
            this.cfService.getFilterdData(payload).subscribe(res => {
                this.loading = false
                if (Object.keys(res.data.data).length > 0 && res.statusCode == 200) {
                    this.data = res.data.data
                    if (e === 'All') {
                        this.filteredData.emit(res.data)
                    }
                    if (e === 'excel') {
                        console.log('filter ' + JSON.stringify(res.data))
                        this.exportExcel.emit({ updatedData: (res.data.data), ...this.customFilterForm.value })
                    }
                    if (e === 'pdf') {
                        this.exportPdf.emit({ updatedData: (res.data.data), ...this.customFilterForm.value })
                    }
                } else {
                    this.data = []
                    this.toastr.error('Sorry, no records were found')
                }
            }, err => {
                console.error(err);
                this.toastr.error(err.error.message)
                this.loading = false
            })
        } else if (e === 'excel') {
            this.exportExcel.emit()
        } else if (e === 'pdf') {
            this.exportPdf.emit()
        }
    }

    datesUpdated(e) {
        if (e && e.startDate && e.endDate && (e.endDate.diff(e.startDate, 'y', true)).toFixed(2) > 3) {
            this.showArchiveButton = true
        } else {
            this.showArchiveButton = false
        }
    }

    getArchiveReport() {
        this.dialog.open(ArchiveReportPopupComponent, {
            width: '50%',
            disableClose: true,
            data: {
                startDate: moment(this.customFilterForm.value.dateRange.startDate).format('YYYY-MM-DD'),
                endDate: moment(this.customFilterForm.value.dateRange.endDate).format('YYYY-MM-DD'),
                reportType: this.type,
                addition_info: `Filter Type - ${this.customFilterForm.value.FilterType}, Filter Value - ${this.customFilterForm.value.FilterValue}`
            }
        });
    }

}
