<br>
<mat-accordion class="example-headers-align">
    <mat-expansion-panel>
        <mat-expansion-panel-header>
            <mat-panel-title>
                <span class="subheading-2">
                    <i class="fa fa-filter"></i>
                    Reports/Advanced Search</span>
            </mat-panel-title>
        </mat-expansion-panel-header>
        <br>
        <form name="customFilterForm" autocomplete="off" [formGroup]="customFilterForm" (ngSubmit)="f.form.valid"
            #f="ngForm">
            <div class="row custom-filter">
                <div class="col-sm-6">
                    <mat-form-field class="full-width" (click)="clear()"  appearance="outline">
                        <mat-select   [disableOptionCentering]="true"   (selectionChange)="getFilter($event.value)" formControlName="FilterType">
                            <mat-option>
                              <ngx-mat-select-search  placeholderLabel="Search"
                              noEntriesFoundLabel="No Matching Found"  (keyup)="applyFilterU($event.target.value)"><mat-icon (click)="clear()" ngxMatSelectSearchClear>close</mat-icon></ngx-mat-select-search>
                            </mat-option>
                                    <mat-option [value]="item" *ngFor="let item of selectFilter">
                                        {{item}}</mat-option>
                          </mat-select>
                        <mat-error *ngIf="customFilterForm.controls['FilterType'].errors?.required">Filter
                            Type is
                            required</mat-error>
                    </mat-form-field>
                </div>
                <div class="col-sm-6">
                    <mat-form-field class="full-width" (click)="clear()" appearance="outline">
                        <mat-select  (selectionChange)="getFilterValue($event.value)" [disableOptionCentering]="true" formControlName="FilterValue">
                            <mat-option>
                              <ngx-mat-select-search  placeholderLabel="Search"
                              noEntriesFoundLabel="No Matching Found"  (keyup)="applyKeyUpFilter($event.target.value)"><mat-icon (click)="clear()" ngxMatSelectSearchClear>close</mat-icon></ngx-mat-select-search>
                            </mat-option>
                                    <mat-option value="All">
                                        All</mat-option>
                                    <mat-option [value]="item" *ngFor="let item of selectValue">
                                        {{item}}</mat-option>
                          </mat-select>
                          <mat-hint align="start" *ngIf="loadingFilter">Loading...</mat-hint>
                        <mat-error *ngIf="customFilterForm.controls['FilterValue'].errors?.required && type !='Reservations Report'">
                            Type is
                            required</mat-error>
                        <mat-error *ngIf="customFilterForm.controls['FilterValue'].errors?.required && type =='Reservations Report'">
                            Reservation is required</mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="row custom-filter">
                <div class="col">
                    <mat-form-field class="full-width" appearance="outline">
                        <input *ngIf="type !='Reservations Report'" matInput type="text" formControlName="dateRange" ngxDaterangepickerMd
                        (datesUpdated)="datesUpdated($event)"
                            [showCustomRangeLabel]="true" [alwaysShowCalendars]="true" [ranges]="ranges"
                            [linkedCalendars]="true" [showClearButton]="true"
                            placeholder="Select Date Range..." />
                            <input *ngIf="type =='Reservations Report'" matInput type="text" formControlName="dateRange" ngxDaterangepickerMd
                        (datesUpdated)="datesUpdated($event)"
                            [showCustomRangeLabel]="true" [alwaysShowCalendars]="true" [ranges]="ranges"
                            [linkedCalendars]="true" [showClearButton]="true"
                            placeholder="Select Date Range..." />
                    </mat-form-field>
                </div>
            </div>
            <mat-action-row>
                <button *ngIf="!showArchiveButton && customFilterForm.valid" type="submit" matTooltip="View Data" (click)="applyCustomFilter('All')"
                    mat-icon-button><span><i class="fa  fa-eye fa-2x"></i></span></button>
                <button *ngIf="customFilterForm.valid && showArchiveButton" type="button" matTooltip="Archive Data" (click)="getArchiveReport()"
                  style="width: 130px"  mat-icon-button><span><i class="fa  fa-archive fa-2x"></i></span>
                Archive Report</button>
                <button *ngIf="customFilterForm.valid && data.length" type="button" matTooltip="Export as Excel" (click)="applyCustomFilter('excel')" mat-icon-button><span><i
                            class="fa  fa-file-excel-o fa-2x"></i></span></button>
                <button *ngIf="customFilterForm.valid && data.length"  type="button" matTooltip="Export as PDF" (click)="applyCustomFilter('pdf')" mat-icon-button><span><i
                            class="fa fa-file-pdf-o fa-2x"></i></span></button>
            </mat-action-row>
        </form>
    </mat-expansion-panel>
</mat-accordion>
<app-loading *ngIf="loading"></app-loading>