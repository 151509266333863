import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Modules } from '../shared/constants/modules';
import { RoutingPath } from '../shared/routes';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class AccessControlService {
  modules = Modules.modulesList.map(e => e.value)
  accessModules = []
  routingList = new BehaviorSubject<any>([]);
  updatedRoutes = this.routingList.asObservable();
  themeColor = new BehaviorSubject<any>('');
  updatedTheme = this.themeColor.asObservable();
  constructor(private authenticationService: AuthenticationService) { }

  updateRoutes(data = RoutingPath.routes) {
    this.accessModules = this.authenticationService.currentUserValue.location.modules
    let updatedRoutes = data.filter(e => {
      if (this.modules.includes(e.name)) {
        if (this.accessModules.includes(e.name)) {
          return e
        }
      } else {
        return e
      }
    })
    this.routingList.next(updatedRoutes)
  }

  updateAppTheme() {
    this.themeColor.next(this.authenticationService.currentUserValue.location)
  }



}
