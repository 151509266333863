<mat-card class="card-style">
    <mat-toolbar class="tool-bar">
        <h4><i class="fa fa-file-image-o"></i> Uploded Image </h4>
    </mat-toolbar>
    <div class="container-fluid">
        <div class="row" cdkFocusInitial>
            <div *ngIf="data" class="col-sm-12 text-center" style="padding-top: 8px;"> 
                <img style="max-height: 484.50px; max-width: 750px;" [src]="data" alt="image" />
            </div>
            <div *ngIf="!data" class="col-sm-12 mt-2 text-center">
                <p>No Image Available</p>
            </div>
            <mat-divider></mat-divider>
            <div class="col-sm-12 text-center">
                <br>
                <button mat-dialog-close class="primary-button" mat-raised-button>Ok</button>
                <br>
            </div>
        </div>

    </div>
    <br>
</mat-card>