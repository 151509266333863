import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
  name: 'dateTimePipe'
})
export class CustomDateTimePipe implements PipeTransform {

  transform(value: any, ...args: any[]): unknown {
    if (value !== 'Invalid date') {
      return new DatePipe('en-US').transform(value, 'MMMM d, y, h:mm a')
    } else {
      return "-"
    }
  }

}


// @Pipe({
//   name: "myDate",
//   pure: true
// })
// export class CustomDateTimePipe extends DatePipe {
//   transform(value: any, pattern: string = "longDate"): string|null {
//       let result = super.transform(value, pattern);
//       result += " " + this.map[Intl.DateTimeFormat().resolvedOptions().timeZone];
//       return result;
//   }
//   map = {
//       "Asia/Calcutta": "IST"
//   };
// }