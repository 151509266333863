import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DonationService {

  constructor(private http: HttpClient) { }

  getAllDonations(size, skip, search, locId,sort,sortorder) {
    let url = environment.SERVER_BASEURL + `donation-management/${locId}/donations?limit=${size}&page=${skip}&sortBy=${sort}&sortOrder=${sortorder}`
    // let url = environment.SERVER_BASEURL + `donations?limit=${size}&page=${skip}&loc_id=${locId}&campagn_id=${campaignId}`
    if (search) {
      url = url + `&search=${search}`
    }
    return this.http.get<any>(url);
  }

  getAllCampaigns(size, skip, search, locId,sort,sortorder) {
    let url = environment.SERVER_BASEURL + `donation-management/${locId}/campaigns?limit=${size}&page=${skip}&sortBy=${sort}&sortOrder=${sortorder}`
    if (search) {
      url = url + `&search=${search}`
    }
    return this.http.get<any>(url);
  }

  createCampaign(loc_id,payload) {
    return this.http.post<any>(environment.SERVER_BASEURL + `donation-management/${loc_id}/campaigns`, payload);
  }
  getCampaign(loc_id,id) {
    return this.http.get<any>(environment.SERVER_BASEURL + `donation-management/${loc_id}/campaigns/${id}`);
  }
  getDonation(loc_id,id) {
    return this.http.get<any>(environment.SERVER_BASEURL + `donation-management/${loc_id}/donations/${id}`);
  }

  updateDonation(loc_id,id,payload) {
    return this.http.put<any>(environment.SERVER_BASEURL + `donation-management/${loc_id}/donations/${id}`, payload);
  }

  UpdateDonationsSetting(locId,id,payload) {
    return this.http.put<any>(environment.SERVER_BASEURL + `donation-management/${locId}/donation-settings/${id}`, payload);
  }
  
  
  sendEmailPayment(loc_id,id,payload) {
    return this.http.post<any>(environment.SERVER_BASEURL + `payment-management/${loc_id}/payments/${id}/email`, payload);
  }

  AddDonationsSetting(locId,payload){
    return this.http.post<any>(environment.SERVER_BASEURL + `donation-management/${locId}/donation-settings`, payload);
  }

  GetDonationsSetting(locId) {
    return this.http.get<any>(environment.SERVER_BASEURL + `donation-management/${locId}/donation-settings`);
  }

  updateCampaign(loc_id,id, payload) {
    return this.http.put<any>(environment.SERVER_BASEURL + `donation-management/${loc_id}/campaigns/${id}`, payload);
  }

  getCampaignDetails(id, limit,page, search, loc_id,sort,sortorder) {
    let url = environment.SERVER_BASEURL + `donation-management/${loc_id}/campaigns/${id}/donations?limit=${limit}&page=${page}&sortBy=${sort}&sortOrder=${sortorder}`
    if (search) {
      url = url + `&search=${search}`
    }
    return this.http.get<any>(url);
  }

  createDonorPayment(payload){
    return this.http.post<any>(environment.SERVER_BASEURL + `payment/donation`, payload);

  }

  getCampaigndrop(loc_id){
    return this.http.get<any>(environment.SERVER_BASEURL + `donation-management/${loc_id}/campaign-lists`);
  }

  deleteCampaign(loc_id,id,payload){
    return this.http.delete<any>(environment.SERVER_BASEURL + `donation-management/${loc_id}/campaigns/${id}`,payload);
  }
}
