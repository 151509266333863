import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

import { AuthenticationService } from 'src/app/services/authentication.service';
import { LocationService } from 'src/app/services/location.service';
import { PaymentService } from 'src/app/services/payment.service';
import { SquarePaymentService } from 'src/app/services/square-payment.service';
import { environment } from 'src/environments/environment';
declare var SqPaymentForm: any;

@Component({
  selector: 'square-card',
  templateUrl: './square-payment.component.html',
  styleUrls: ['./square-payment.component.scss']
})
export class SquarePaymentComponent implements OnInit {

  @Input() userId
  @Input() loc_id
  @Input() buttonStyles
  @Input() name
  @Input() grandTotal
  @Input() itemDetails
  // Output Variables
  @Output() paymentResponse = new EventEmitter<any>()

  creditCardFormForSquare: FormGroup
  loading: boolean;
  squareToken
  paymentForm: any;
  constructor(private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private authService: AuthenticationService,
    private paymentService: PaymentService,
    private locationService: LocationService,
    private squarePaymentService: SquarePaymentService,
  ) { }

  async ngOnInit() {
    this.loading = true
    const current = this
    if (environment.production) {
      await this.squarePaymentService.loadScript('square');
    } else {
      await this.squarePaymentService.loadScript('square-sandbox');
    }

    this.locationService.getPaymentGateways(this.authService.currentUserValue ? this.authService.currentUserValue.loc_id : this.loc_id).subscribe(result => {
      let gw = result.data.filter(gw => gw.status === 'Active')[0]
      if (gw.gateway_name === 'Square') {
        this.paymentForm = new SqPaymentForm({
          applicationId: gw.gateway_password,
          inputClass: 'sq-input',
          autoBuild: false,
          // Customize the CSS for SqPaymentForm iframe elements
          inputStyles: [{
            fontSize: '16px',
            lineHeight: '24px',
            padding: '8px',
            placeholderColor: '#a0a0a0',
            backgroundColor: 'white'
          }],
          // Initialize the credit card placeholders
          cardNumber: {
            elementId: 'sq-card-number',
            placeholder: 'Card Number'
          },
          cvv: {
            elementId: 'sq-cvv',
            placeholder: 'CVV'
          },
          expirationDate: {
            elementId: 'sq-expiration-date',
            placeholder: 'MM/YY'
          },
          postalCode: {
            elementId: 'sq-postal-code',
            placeholder: 'Postal'
          },
          // SqPaymentForm callback functions
          callbacks: {
            /*
            * callback function: cardNonceResponseReceived
            * Triggered when: SqPaymentForm completes a card payment token request
            */
            cardNonceResponseReceived: function (errors, nonce, cardData, billingContact, shippingContact, shippingOption) {

              if (errors) {
                // Log errors from payment token generation to the browser developer console.
                console.error('Encountered errors:');
                errors.forEach(function (error) {
                  console.log(error.message);
                  current.toastr.error(error.message);
                });
                return;
              }
              console.log(nonce, "nonce")
              current.squareToken = nonce
              current.pay()
            }
          }
        });
        this.paymentForm.build();
        this.creditCardFormForSquare = this.formBuilder.group({
          payment_method: "",
          Notes: '',
          name: ''
        });
        this.loading = false
      }
    })

  }


  payForCreditCardWithSquare() {
    this.paymentForm.requestCardNonce();
  }

  pay() {
    this.loading = true
    let payload = {
      source_id: this.squareToken,
      itemDetails: this.itemDetails,
      payment_desc: this.itemDetails.payment_desc,      
      loc_id: this.authService.currentUserValue ? this.authService.currentUserValue.loc_id : this.loc_id,
    }
    if (this.itemDetails.purchaseItems) {
      if (this.itemDetails.purchaseItems[0]['key']) {
        payload.itemDetails.purchaseItems[0]['campaignId'] = this.userId
        payload = {
          ...payload,
          ...payload.itemDetails.purchaseItems[0]['donationData']
        }
        delete payload.itemDetails.purchaseItems[0]['key']
        delete payload.itemDetails.purchaseItems[0]['donationData']
      } else {
        payload['userId'] = this.userId
      }
    } else {
      payload['userId'] = this.userId
    }
    // if (this.itemDetails.purchaseItems[0].campaignId) {
    //   this.paymentService.paySquareDonation(this.authService.currentUserValue ? this.authService.currentUserValue.loc_id : this.loc_id, payload).subscribe(res => {
    //     // this.paymentService.payStripe(payload).subscribe(res => {
    //     if (res) {
    //       this.toastr.success(res.message);
    //       this.paymentResponse.emit({ status: true, response: res.data })
    //       this.loading = false
    //     }
    //   }, err => {
    //     this.loading = false
    //     this.toastr.error(err.error.message);
    //     console.log(err)
    //   })
    // } else {
    this.paymentService.paySquare(this.authService.currentUserValue ? this.authService.currentUserValue.loc_id : this.loc_id, payload).subscribe(res => {
      // this.paymentService.payStripe(payload).subscribe(res => {
      if (res) {
        this.toastr.success(res.message);
        this.paymentResponse.emit({ status: true, response: res.data })
        this.loading = false
      }
    }, err => {
      this.loading = false
      this.toastr.error(err.error.message);
      console.log(err)
    })
    // }
  }

}


