import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { LocationService } from 'src/app/services/location.service';
import { OrganizationService } from 'src/app/services/organization.service';
import { PaymentService } from 'src/app/services/payment.service';
import { ReservationService } from 'src/app/services/reservation.service';

@Component({
  selector: 'app-view-payment',
  templateUrl: './view-payment.component.html',
  styleUrls: ['./view-payment.component.scss']
})
export class ViewPaymentComponent implements OnInit {
  id;
  locationData;
  paymentData;
  loading;
  org_name;
  reservation = false
  organisationList;
  suppliesList;
  total = 0;
  print = false;
  view = false;
  constructor(
    private titleService: Title,
    private route: ActivatedRoute,
    private locationService: LocationService,
    private reservationService: ReservationService,
    private authService: AuthenticationService,
    private orgService: OrganizationService,
    private paymentService: PaymentService

  ) { }

  ngOnInit(): void {
    this.loading = true
    this.id = this.route.snapshot.queryParamMap.get('id');
    this.getPaymentDetails();
    this.locationData = this.authService.currentUserValue.location
    // }
  }

  getPaymentDetails() {
    this.loading = true
    this.paymentService.getPayment(this.authService.currentUserValue.loc_id, this.id).subscribe(res => {
      this.paymentData = res.data[0]
      this.titleService.setTitle("Invoice #" + this.paymentData.payment_id)
      this.amtot(this.paymentData)
      // this.getOrganisations(this.paymentData.user[0].organizations)
      if (this.paymentData.reservation_id > 0) {
        this.reservation = true
        this.reservationService.getReservationSupply(this.paymentData.reservation_id).subscribe(res => {
          this.suppliesList = res.data
          this.loading = false
          // if(this.print){
          setTimeout(() => {
            window.print();
          }, 2500);
          // }
        }, err => {
          console.error(err)
          this.loading = false
        })
      } else {
        // if(this.print){
        setTimeout(() => {
          window.print();
        }, 2500);
        // }
        this.loading = false
      }
    }, err => {
      console.error(err)
      this.loading = false
    })
  }
  getOrganisations(id) {
    this.orgService.getOrgById(this.authService.currentUserValue.loc_id, id).subscribe(res => {
      const organisationdetails = res.data
    }, err => {
      console.log(err)
    })
  }

  amtot(paymentD) {
    if (paymentD.payment_method == 'Complimentary') {
      this.total = 0.00
    } else {
      this.total = paymentD.amount
    }
  }

  getPaymentType(paymentType) {
    return paymentType == ('CC' || 'Credit Card') ? 'Credit Card' : paymentType;
  }

}

