<mat-dialog-content [style.--dynamic-primary-color]="themeColor" class="mat-typography">
	<mat-card class="card-style">
		<mat-toolbar class="tool-bar">
			<h4><i class="fa fa-exclamation-triangle"></i>{{headerTxt}}  </h4>
		</mat-toolbar>
		<div class="container-fluid">
			<br />
			<div class="row">
				<div class="col-sm-12">
					<p class="text-center" style="font-size: 20px;" cdkFocusInitial>
						{{message}}
					</p>
				</div>
			</div>
			<br />
			<div class="row">
				<div class="col-sm-12 text-center">
					<button mat-button mat-raised-button class="primary-button"
						(click)="onConfirmClick()">{{confirmButtonText}}</button>
					<button style="background-color: lightgrey;margin-left:10px" mat-button mat-raised-button mat-dialog-close>{{cancelButtonText}}</button>
				</div>
			</div>
			<br />
		</div>
	</mat-card>
</mat-dialog-content>