<mat-card class="card">
    <mat-toolbar class="tool-bar">
        <h4> <span class="fa fa-archive"></span> Archive Report Request</h4>
    </mat-toolbar>
    <form name="achiveForm" autocomplete="off" (ngSubmit)="f.form.valid && submit()" #f="ngForm">
        <div class="container-fluid">
            <div class="row">

                <div class="col-sm-12">
                    <br>
                    <label class="input-label">Subject</label>
                    <mat-form-field class="full-width" appearance="outline">
                        <input name="subject" matInput [(ngModel)]="subject">
                    </mat-form-field>
                </div>
                <div class="col-sm-12">
                    <label class="input-label">Any Special Request/Notes</label>
                    <mat-form-field class="full-width" appearance="outline">
                        <textarea name="additional_info" rows="4" [(ngModel)]="additional_info" matInput>
                </textarea>
                    </mat-form-field>
                </div>
                <div class="col-sm-12">
                    <button type="submit" mat-raised-button class="primary-button">Send Request</button>
                    <button mat-dialog-close style="margin-left: 10px;" mat-button class="secondary-button"
                        mat-raised-button>Cancel</button>
                </div>
            </div>
            <br>
        </div>

    </form>
</mat-card>