
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ClassesService {

  public session: Object
  constructor(private http: HttpClient) { }

  public setSession(data) {
    this.session = data
  }

  public getSession() {
    return this.session
  }

  // Class
  getAllClasses(page, locationId, size, search, filter, sortBy, sortOrder) {
    let url = `class-management/${locationId}/classes`
    if (page && size) {
      url = url + `?page=${page}&limit=${size}`
      if (sortBy && sortOrder) {
        url = url + `&sortBy=${sortBy}&sortOrder=${sortOrder}`
      }
      if (filter) {
        url = url + `&mode=${filter}`
      }
      if (search) {
        url = url + `&search=${search}`
      }
    } else {
      url = url + `?mode=${filter}&page=${page}`
    }
    return this.http.get<any>(environment.SERVER_BASEURL + url);


    // let url = environment.SERVER_BASEURL + `class-management/${locationId}/classes?page=${page}&limit=${size}`
    // if (search) {
    //   url = url + `&search=${search}`
    // }
    // if (filter) {
    //   url = url + `&mode=${filter}`
    // }
    // return this.http.get<any>(url);
  }

  addClass(locationId, payload) {
    return this.http.post<any>(environment.SERVER_BASEURL + `class-management/${locationId}/classes`, payload);
  }

  updateClass(locationId, id, payload) {
    return this.http.put<any>(environment.SERVER_BASEURL + `class-management/${locationId}/classes/${id}`, payload);
  }

  getClassById(locationId, id) {
    return this.http.get<any>(environment.SERVER_BASEURL + `class-management/${locationId}/classes/${id}`);
  }

  deleteClass(locationId, id) {
    return this.http.delete<any>(environment.SERVER_BASEURL + `class-management/${locationId}/classes/${id}`);
  }

  getClassesCount(locationId) {
    return this.http.get<any>(environment.SERVER_BASEURL + `class-management/${locationId}/classes/count`);
  }

  // Session
  getAllSessions(locationId, classTID, limit, page, search) {
    let url = `class-management/${locationId}/classes/${classTID}/sessions`
    if (page && limit) {
      url = url + `?page=${page}&limit=${limit}`
    }
    if (search) {
      url = url + `&search=${search}`
    }
    return this.http.get<any>(environment.SERVER_BASEURL + url);
  }

  createSession(locationId, classTId, payload) {
    return this.http.post<any>(environment.SERVER_BASEURL + `class-management/${locationId}/classes/${classTId}/sessions`, payload);
  }

  updateSession(locationId, classTID, sessionId, payload) {
    return this.http.put<any>(environment.SERVER_BASEURL + `class-management/${locationId}/classes/${classTID}/sessions/${sessionId}`, payload);
  }

  // Schedule
  createSchedule(locationId, classTID, sessionId, payload) {
    return this.http.post<any>(environment.SERVER_BASEURL + `class-management/${locationId}/classes/${classTID}/sessions/${sessionId}/schedules`, payload);
  }

  updateSchedule(locationId, classTID, sessionId, scheduleId, payload) {
    return this.http.put<any>(environment.SERVER_BASEURL + `class-management/${locationId}/classes/${classTID}/sessions/${sessionId}/schedules/${scheduleId}`, payload);
  }

  getAllSchedules(locationId, classTID, sessionId, page, limit, search) {
    let url = `class-management/${locationId}/classes/${classTID}/sessions/${sessionId}/schedules?page=${page}&limit=${limit}`
    if (search) {
      url = url + `&search=${search}`
    }

    return this.http.get<any>(environment.SERVER_BASEURL + url);
  }

  // Participants
  getAllParticipants(locationId, classTID, sessionId, page, limit, search, sort, sortorder) {
    let url = environment.SERVER_BASEURL + `class-management/${locationId}/classes/${classTID}/sessions/${sessionId}/participants?page=${page}&limit=${limit}&sortBy=${sort}&sortOrder=${sortorder}`
    if (search) {
      url = url + `&search=${search}`
    }

    return this.http.get<any>(url);
  }

  addParticipant(locationId, classTID, sessionId, payload) {
    return this.http.post<any>(environment.SERVER_BASEURL + `class-management/${locationId}/classes/${classTID}/sessions/${sessionId}/participants`, payload);
  }

  updateParticipant(locationId, classTID, sessionId, participantId, payload) {
    return this.http.put<any>(environment.SERVER_BASEURL + `class-management/${locationId}/classes/${classTID}/sessions/${sessionId}/participants/${participantId}`, payload);
  }

  getAllClassesDropdown(loc_id) {
    return this.http.get<any>(environment.SERVER_BASEURL + `class-management/${loc_id}/classes-list`);
  }


  getAllEquipments(loc_id, page, limit) {
    return this.http.get<any>(environment.SERVER_BASEURL + 'equipments?page=' + page + '&limit=' + limit + '&loc_id=' + loc_id);
  }

  getClassEquipments(loc_id) {
    return this.http.get<any>(environment.SERVER_BASEURL + 'equipdrop?loc_id=' + loc_id);
  }

  getClassByIdV0(id) {
    return this.http.get<any>(environment.SERVER_BASEURL + `classV0/${id}`);
  }

  updateClassDetails(id, payload) {
    return this.http.put<any>(environment.SERVER_BASEURL + `class/details/${id}`, payload);
  }

  getUpcomingClasses(classID, page, limit) {
    return this.http.get<any>(environment.SERVER_BASEURL + 'class/upcoming?page=' + page + '&limit=' + limit + '&classID=' + classID);
  }
  getAllUpcomingClasses(loc_id) {
    return this.http.get<any>(environment.SERVER_BASEURL + `class/upcoming?page=1&limit=10&loc_id=${loc_id}`);
  }

  getPreviousClasses(classID, page, limit) {
    return this.http.get<any>(environment.SERVER_BASEURL + 'class/previous?page=' + page + '&limit=' + limit + '&classID=' + classID);
  }

  getCertifiedMembers(loc_id, classID, page, limit) {
    return this.http.get<any>(environment.SERVER_BASEURL + 'class/certify?loc_id=' + loc_id + '&class_id=' + classID + '&page=' + page + '&limit=' + limit);
  }

  addMemcertify(payload) {
    return this.http.post<any>(environment.SERVER_BASEURL + `class/certify`, payload);
  }

  addClassSchedule(payload) {
    return this.http.post<any>(environment.SERVER_BASEURL + `class/schedule`, payload);
  }

  updateClassSchedule(id, payload) {
    return this.http.put<any>(environment.SERVER_BASEURL + `class/schedule/${id}`, payload);
  }

  getClassParticipantsById(id, type) {
    if (type == 'previous') {
      return this.http.get<any>(environment.SERVER_BASEURL + `class/previous/${id}`);
    } else {
      return this.http.get<any>(environment.SERVER_BASEURL + `class/upcoming/${id}`);
    }
  }

  addSchedule(payload) {
    return this.http.post<any>(environment.SERVER_BASEURL + `class/time`, payload);
  }

  getClassSchedules(loc_id, class_id, limit, page, search) {
    let url = `class/schedulebyid?loc_id=${loc_id}&class_id=${class_id}&page=${page}&limit=${limit}`
    if (search) {
      url = url + `&search=${search}`
    }
    return this.http.get<any>(environment.SERVER_BASEURL + url);
  }


  // updateSchedule(id, payload) {
  //   return this.http.put<any>(environment.SERVER_BASEURL + `class/time/${id}`, payload);
  // }

  deleteClassById(id) {
    return this.http.delete<any>(environment.SERVER_BASEURL + `class/upcoming/${id}`);
  }

  deleteScheduleById(id) {
    return this.http.delete<any>(environment.SERVER_BASEURL + `class/time/${id}`);
  }

  // updateParticipant(id, payload) {
  //   return this.http.put<any>(environment.SERVER_BASEURL + `classmember/${id}`, payload);
  // }

  updateParticipants(id, payload) {
    return this.http.put<any>(environment.SERVER_BASEURL + `class/members/${id}`, payload);
  }

  deleteParticipant(id) {
    return this.http.delete<any>(environment.SERVER_BASEURL + `class/members/${id}`);
  }

  activateClass(id, payload) {
    return this.http.put<any>(environment.SERVER_BASEURL + `class/status/${id}`, payload);
  }

  getActiveClasses(page, locationId, size, search, filter) {
    let url = environment.SERVER_BASEURL + `class-management/${locationId}/classes?page=${page}&limit=${size}&mode=${filter}`
    if (search) {
      url = url + `&search=${search}`
    }
    return this.http.get<any>(url);
  }

  activeClassesList(loc_id) {
    return this.http.get<any>(environment.SERVER_BASEURL + 'classes/drop?loc_id=' + loc_id);
  }

  updateClassMem(id, payload) {
    return this.http.put<any>(environment.SERVER_BASEURL + `class/member-pay/${id}`, payload);
  }

  checkEquipAvail(equipid, startDate, endDate) {
    return this.http.get<any>(environment.SERVER_BASEURL + `class/equip?equip_id=${equipid}&start_date=${startDate}&end_date=${endDate}`);
  }

  checkEquipTagAvail(tagNo, startDate, endDate) {
    return this.http.get<any>(environment.SERVER_BASEURL + `class/equip-resrv?equip_id=${tagNo}&start_date=${startDate}&end_date=${endDate}`);
  }

  // checkrequisite(tagNo, loc_id, userId) {
  //   return this.http.get<any>(environment.SERVER_BASEURL + `equipclass/requisite?equip_id=${tagNo}&loc_id=${loc_id}&user_id=${userId}`);
  // }
  checkrequisite(tagNo, loc_id, userId) {
    return this.http.get<any>(environment.SERVER_BASEURL + `equipment-management/${loc_id}/equipments/${tagNo}/classes/${userId}`);
  }

  deleteClassTime(payload) {
    return this.http.delete<any>(environment.SERVER_BASEURL + `classtime`, payload);
  }

  // getAllSessions(classPID, page, locationId, size, search) {
  //   let url = environment.SERVER_BASEURL + `class/schedule?page=${page}&loc_id=${locationId}&limit=${size}&class_id=${classPID}`
  //   if (search) {
  //     url = url + `&search=${search}`
  //   }

  //   return this.http.get<any>(url);
  // }

}
