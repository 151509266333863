import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { LocationService } from 'src/app/services/location.service';
import { PaymentService } from 'src/app/services/payment.service';
import * as _moment from 'moment';
const moment = _moment

@Component({
  selector: 'app-invoice-generator',
  templateUrl: './invoice-generator.component.html',
  styleUrls: ['./invoice-generator.component.scss']
})
export class InvoiceGeneratorComponent implements OnInit {
  userLevel
  locationId
  locationData
  loading = false
  paymentDone = false
  paymentGateway
  amount = 0
  paymentData
  userId
  subscriptionDate
  expiredDate

  constructor(
    private route: ActivatedRoute,
    private paymentService: PaymentService,
    private toastr: ToastrService,
    private locationService: LocationService,
    private authService: AuthenticationService,
    private datePipe: DatePipe
  ) {
    this.route
      .queryParams
      .subscribe(params => {
        this.loading = true
        this.locationId = params.id
      })
  }


  ngOnInit(): void {
    this.getLocationByid();
  }

  getLocationByid() {
    this.loading = true
    if (this.locationId) {
      this.locationService.getInvoiceId().subscribe(invoiceRes => {
        if (invoiceRes) {
          this.locationService.getLocationById(this.locationId).subscribe(res => {
            if (res) {
              res.data.nxtInvoiceId = invoiceRes.data
              this.locationData = res.data
              console.log(this.locationData)
              if (this.locationData.revenue <= 250000) {
                this.amount = 500;
              }
              if (this.locationData.revenue > 250000 && this.locationData.revenue <= 500000) {
                this.amount = 750;
              }
              if (this.locationData.revenue > 500000) {
                this.amount = 1000;
              }
              this.expiredDate = this.datePipe.transform(this.locationData.dateExpires, 'MMMM dd, y', 'UTC')
              this.subscriptionDate = this.expiredDate + ' - ' + this.datePipe.transform(moment(this.expiredDate).add(1, 'year'), 'MMMM dd, y', 'UTC')
              this.loading = false;
              setTimeout(() => {
                window.print();
              }, 2000);
            }
          }, err => {
            console.log(err)
            this.toastr.error(err)
            this.loading = false;
          })
        }
      }, err => {
        console.log(err)
        this.toastr.error(err)
        this.loading = false;
      })

    }
  }
}
