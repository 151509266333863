<form *ngIf="creditCardFormForSquare" name="creditCardFormForSquare" autocomplete="off"
  [formGroup]="creditCardFormForSquare" (ngSubmit)="cf.form.valid && payForCreditCardWithSquare()" #cf="ngForm"
  class="container-fluid">
  <mat-card class="form-card">
    <span class="card-heading"><i class="fa fa-credit-card" aria-hidden="true"></i>Credit Card</span>
    <br>
    <div class="row">
      <div class="col-sm-12">
        <br>
        <label class="input-label">Card Holder Name</label>
        <mat-form-field class="full-width" appearance="outline">
          <input formControlName="name" matInput placeholder="Card Holder Name" value="Sushi">
        </mat-form-field>
      </div>
      <div class="col-sm-12">
        <label style="margin-bottom: 10px" class="input-label">Credit Card Details<span>*</span></label>
        <div id="sq-card-number"></div>
        <div class="third" id="sq-expiration-date"></div>
        <div class="third" id="sq-cvv"></div>
        <div class="third" id="sq-postal-code"></div>
      </div>

      <div class="col-sm-12">
        <br>
        <label class="input-label">Notes</label>
        <mat-form-field class="full-width" appearance="outline">
          <textarea formControlName="Notes" rows="4" matInput placeholder="Notes"></textarea>
          <mat-hint>e.g Payment Notes.</mat-hint>
        </mat-form-field>
      </div>
    </div>
  </mat-card>
  <br>
  <div class="row">
    <div class="col-sm-12">
      <button id="sq-creditcard" class="button-credit-card primary-button" [ngStyle]="buttonStyles" type="submit"
        mat-raised-button>Pay
        Now</button>
    </div>
  </div>
</form>
<app-loading *ngIf="loading"></app-loading>