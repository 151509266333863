import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-delete-dialog',
  templateUrl: './confirm-delete-dialog.component.html',
  styleUrls: ['./confirm-delete-dialog.component.scss']
})
export class ConfirmDeleteDialogComponent implements OnInit {

  message: string
  confirmButtonText: string
  cancelButtonText: string
  headerTxt: string
  themeColor
  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<ConfirmDeleteDialogComponent>) {
    if (data) {
      this.message = data.message;
      if(data.themeColor != "Default_Red"){
        this.themeColor = data.themeColor
      }else{
        this.themeColor = '#ff0000'
      }
      if(this.message=='Do you have an existing account?'){
        this.headerTxt = 'Account Status'
      }else{
        this.headerTxt = 'Confirm Delete'
      }
      if (data.buttonText) {
        this.confirmButtonText = data.buttonText.ok;
        this.cancelButtonText = data.buttonText.cancel;
      }
    }
  }

  onConfirmClick(): void {
    this.dialogRef.close(true);
  }

  ngOnInit(): void {
  }

}
