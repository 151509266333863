import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './shared/material.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtInterceptor } from './helpers/jwt.interseptor';
import { PhoneMaskDirective } from './directives/phone-mask.directive';
import { ToastrModule } from 'ngx-toastr';
import { AuthGuard } from './shared/auth.guard';
import { DatePipe } from '@angular/common';
import { ViewImageDialogComponent } from './components/view-image-dialog/view-image-dialog.component';
import { ResetPasswordComponent } from './modules/authentication/reset-password/reset-password.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MemberSignupComponent } from './modules/authentication/member-signup/member-signup.component';
import { TextMaskModule } from 'angular2-text-mask';
import { ErrorInterceptor } from './shared/error.interseptor';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { ConfirmDeleteDialogComponent } from './components/confirm-delete-dialog/confirm-delete-dialog.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import {CustomFilterModule} from './shared/customFilter/custom-filter.module'
import {  NgxMatDatetimePickerModule, NgxMatNativeDateModule, NgxMatTimepickerModule 
} from '@angular-material-components/datetime-picker'
import { NgxStripeModule } from 'ngx-stripe';
import { CustomDatePipeModule } from './shared/pipes/custom.pipe.module';
import { LoadingModule } from './components/loading/loading.module';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';

@NgModule({
  declarations: [
    AppComponent,
    PhoneMaskDirective,
    ViewImageDialogComponent,
    ResetPasswordComponent,
    PhoneMaskDirective,
    MemberSignupComponent,
    ConfirmDeleteDialogComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    HttpClientModule,
    FormsModule,
    CustomFilterModule,
    ReactiveFormsModule,
    TextMaskModule,
    NgxMatSelectSearchModule,
    MatSelectModule,
    MatFormFieldModule,
    NgxMatDatetimePickerModule,
    NgxMatNativeDateModule,
    NgxMatTimepickerModule,
    NgxMaterialTimepickerModule,
    CustomDatePipeModule,
    LoadingModule,
    NgxStripeModule.forRoot("pk_test_nDR7IWEIGLp4a1SBtqKH5eyg"),
    ToastrModule.forRoot()
  ],
  exports: [
    PhoneMaskDirective
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    AuthGuard,
    DatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
