import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { StripeCardComponent, StripeFactoryService, StripeInstance } from 'ngx-stripe';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { LocationService } from 'src/app/services/location.service';
import { PaymentService } from 'src/app/services/payment.service';

@Component({
  selector: 'stripe-card',
  templateUrl: './stripe.component.html',
  styleUrls: ['./stripe.component.scss']
})
export class StripeComponent implements OnInit {
  // Input Variables
  @Input() userId
  @Input() loc_id
  @Input() buttonStyles
  @Input() name
  @Input() grandTotal
  @Input() paymentDesc
  @Input() itemDetails
  // Output Variables
  @Output() paymentResponse = new EventEmitter<any>()
  @ViewChild(StripeCardComponent) card: StripeCardComponent;

  stripe: StripeInstance;
  creditCardFormForStripe: FormGroup
  loading: boolean;
  stripeCardValid: boolean = false;
  stripeTokenObject
  cardOptions = {
    hidePostalCode: true,
    style: {
      base: {
        iconColor: '#666EE8',
        color: '#31325F',
        fontWeight: 300,
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSize: '18px',
        '::placeholder': {
          color: '#CFD7E0'
        }
      }
    }
  };
  constructor(private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private authService: AuthenticationService,
    private paymentService: PaymentService,
    private stripeFactoryService: StripeFactoryService,
    private locationService: LocationService
  ) { }

  ngOnInit(): void {

    this.locationService.getPaymentGateways(this.authService.currentUserValue ? this.authService.currentUserValue.loc_id : this.loc_id).subscribe(result => {
      let gw = result.data.filter(gw => gw.status === 'Active')[0]
      if (gw.gateway_name === 'Stripe') {
        console.log(gw.gateway_password)
        this.stripe = this.stripeFactoryService.create(gw.gateway_password)
        console.log(this.stripe)
        this.creditCardFormForStripe = this.formBuilder.group({
          payment_method: "",
          Notes: '',
          name: ''
        });
      }
    })

  }

  onChange(event) {
    this.stripeCardValid = event.complete;

  }

  get validFormForStripe() {
    return this.stripeCardValid;
  }

  payForCreditCardWithStripe() {
    // console.log(this.paymentForm.controls.userID.value)
    this.loading = true
    this.stripe
      .createToken(this.card.getCard(), this.name)
      .subscribe(result => {
        if (result.token) {
          this.stripeTokenObject = result.token
          // if(!this.itemDetails.purchaseItems.total){
          //   this.itemDetails.total = this.itemDetails.amount
          //   this.itemDetails['purchaseItem']=this.itemDetails
          // }
          console.log(this.itemDetails)
          let payload = {
            stripeToken: result.token.id,
            itemDetails: this.itemDetails,
            loc_id: this.authService.currentUserValue ? this.authService.currentUserValue.loc_id : this.loc_id,
            payment_desc: this.paymentDesc
          }
          if (this.itemDetails.purchaseItems) {
            if (this.itemDetails.purchaseItems[0]['key']) {
              payload.itemDetails.purchaseItems[0]['campaignId'] = this.userId
              payload = {
                ...payload,
                ...payload.itemDetails.purchaseItems[0]['donationData']
              }
              delete payload.itemDetails.purchaseItems[0]['key']
              delete payload.itemDetails.purchaseItems[0]['donationData']
            } else {
              payload['userId'] = this.userId
            }
          } else {
            payload['userId'] = this.userId
          }
          console.log(payload)
          this.paymentService.payStripe(this.authService.currentUserValue ? this.authService.currentUserValue.loc_id : this.loc_id, payload).subscribe(res => {
            // this.paymentService.payStripe(payload).subscribe(res => {
            if (res) {
              this.toastr.success(res.message);
              this.paymentResponse.emit({ status: true, response: res.data })
              this.loading = false
            }
          }, err => {
            this.loading = false
            this.toastr.error(err.error.message);
            console.log(err)
          })
        } else if (result.error) {
          this.loading = false
          this.toastr.error(result.error.message);
          console.log(result.error.message);
        }
      });

  }

}
