import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';


/*
 * Raise the value exponentially
 * Takes an exponent argument that defaults to 1.
 * Usage:
 *   value | exponentialStrength:exponent
 * Example:
 *   {{ 2 | exponentialStrength:10 }}
 *   formats to: 1024
*/


@Pipe({ name: 'datePipe' })
export class CustomDatePipe implements PipeTransform {
    transform(value: string): string {
        if (value !== 'Invalid date') {
            return new DatePipe('en-US').transform(value, 'mediumDate')
        } else {
            return "-"
        }
    }
}