import { Injectable, OnInit } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AccessControlService } from '../services/access-control.service';
import { AuthenticationService } from '../services/authentication.service';

@Injectable()
export class AuthGuard implements CanActivate {
  public routingList
  constructor(private router: Router, private authenticationService: AuthenticationService, public accessService: AccessControlService) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      let url: string = state.url;
    const currentUser = this.authenticationService.currentUserValue;

    if (currentUser) {
      this.accessService.updateRoutes()
      this.accessService.updatedRoutes.subscribe(routes => {
        if (routes) {
          this.routingList = routes
        }
      })
      // check if route is restricted by role
      // console.log(currentUser.user_level,route.data.access.includes(currentUser.user_level),'user')
      if (route.data && route.data.access) {
        if (this.routingList && this.routingList.map(e => e.name).includes(route.data.module) || route.data.module == 'Common') {
          if (!route.data.access.includes(parseFloat(currentUser.user_level))) {
            // role not authorised so redirect to error page
            this.router.navigate(['/error']);
            return false;
          }
        } else {
          this.router.navigate(['/error']);
        }
      }

      // check if location & user are active
      // if (currentUser.user_level < 5) {
      //   if (currentUser.location.status == 'Active') {
      //     if (currentUser.status == 'Active') {
      //     } else {
      //       console.log('user inactive')
      //       this.router.navigate(['inactive-account']);
      //     }
      //   } else {
      //     console.log('loc inactive')
      //     this.router.navigate(['inactive-account']);
      //   }
      // }
      if (currentUser.user_level < 5) {
        if (currentUser.location.status == 'Active') {
          if (currentUser.status == 'Active') {
          } else {
            if(currentUser.user_level >= 3 || currentUser.user_level == 0.5){
            } else{
              console.log('user inactive')
              this.router.navigate(['inactive-account']);
            }
          }
        } else {
          console.log('loc inactive')
          this.router.navigate(['inactive-account']);
        }
      }
      return true;
    }

    // Store the attempted URL for redirecting
    localStorage.setItem('redirectUrl',url)

    // not logged in so redirect to login page with the return url
    this.router.navigate(['/login']);
    
    return false;
  }
}
