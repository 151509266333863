import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomFilterComponent } from './custom-filter.component';
import { MaterialModule } from 'src/app/shared/material.module';
import { LoadingModule } from 'src/app/components/loading/loading.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';

@NgModule({
  declarations: [CustomFilterComponent],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMatSelectSearchModule,
    LoadingModule,
    NgxDaterangepickerMd.forRoot()
  ],
  exports: [CustomFilterComponent] 
})
export class CustomFilterModule { }
