import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  constructor(private http: HttpClient) { }

  pay(loc_id, payload) {
    return this.http.post<any>(environment.SERVER_BASEURL + `payment-management/${loc_id}/payments`, payload);
  }

  // makePayment(payload) {
  //   return this.http.post<any>(environment.SERVER_BASEURL + `payment/cash`, payload);
  // }

  makePayment(loc_id, mode, payload) {
    return this.http.post<any>(environment.SERVER_BASEURL + `payment-management/${loc_id}/payments?mode=${mode}`, payload);
  }

  getAllPayments(page, size, search, loc_id, sort, sortorder) {
    let url = environment.SERVER_BASEURL + `payment-management/${loc_id}/payments?page=${page}&limit=${size}&sortBy=${sort}&sortOrder=${sortorder}`
    if (search) {
      url = url + `&search=${search}`
    }
    return this.http.get<any>(url);
  }

  getAllPaymentTypes(page, size, search, loc_id) {
    let url = environment.SERVER_BASEURL + `payment-management/${loc_id}/payment-types?page=${page}&loc_id=${loc_id}&limit=${size}`
    if (search) {
      url = url + `&search=${search}`
    }
    return this.http.get<any>(url);
  }

  createPaymentType(loc_id, payload) {
    return this.http.post<any>(environment.SERVER_BASEURL + `payment-management/${loc_id}/payment-types`, payload)
  }

  updatePaymentType(loc_id, payload, id) {
    return this.http.put<any>(environment.SERVER_BASEURL + `payment-management/${loc_id}/payment-types/${id}`, payload)
  }

  deletePaymenttype(loc_id, id) {
    return this.http.delete<any>(environment.SERVER_BASEURL + `payment-management/${loc_id}/payment-types/${id}`);
  }

  getPayment(loc_id, id) {
    return this.http.get<any>(environment.SERVER_BASEURL + `payment-management/${loc_id}/payments/${id}`);
  }

  refundPayment(loc_id, id, payload) {
    return this.http.post<any>(environment.SERVER_BASEURL + `payment-management/${loc_id}/payments/${id}/refund`, payload);
  }

  getPaymentTypes(loc_id) {
    return this.http.get<any>(environment.SERVER_BASEURL + `payment-management/${loc_id}/payment-types?mode=Dropdown`);
  }

  sendEmailPayment(payload) {
    return this.http.post<any>(environment.SERVER_BASEURL + `payment/email`, payload);
  }

  createOrders(payload) {
    return this.http.post<any>(environment.SERVER_BASEURL + 'payment/order', payload)
  }

  capturePayment(payload) {
    const { paymentId, token, payer_id, userId, locId } = payload
    return this.http.get<any>(environment.SERVER_BASEURL + `payment/order/cb-approve?userId=${userId}&locId=${locId}&paymentId=${paymentId}&token=${token}&PayerID=${payer_id}`)
  }

  captureDonation(payload) {
    const { paymentId, token, payer_id, locId } = payload
    return this.http.get<any>(environment.SERVER_BASEURL + `payment/donation/approve?locId=${locId}&paymentId=${paymentId}&token=${token}&PayerID=${payer_id}`)
  }

  payStripe(loc_id, payload) {
    return this.http.post<any>(environment.SERVER_BASEURL + `payment-management/${loc_id}/payments?mode=charge`, payload)
  }

  payPaypal(loc_id, payload) {
    return this.http.post<any>(environment.SERVER_BASEURL + `payment-management/${loc_id}/payments`, payload)
  }

  payDonationPaypal(loc_id, payload) {
    return this.http.post<any>(environment.SERVER_BASEURL + `payments/donation`, payload)
  }

  paySquare(loc_id, payload) {
    return this.http.post<any>(environment.SERVER_BASEURL + `payment-management/${loc_id}/payments?mode=square`, payload)
  }

  paySquareDonation(loc_id, payload) {
    return this.http.post<any>(environment.SERVER_BASEURL + `payments/donation?mode=square`, payload)
  }

  getPayments(page, size, search, loc_id, userId) {
    let url = environment.SERVER_BASEURL + `payments/user?page=${page}&loc_id=${loc_id}&limit=${size}&user_id=${userId}`
    if (search) {
      url = url + `&search=${search}`
    }
    return this.http.get<any>(url);
  }
}
