export class MembersData {


    public static membershipType = [

        { value: "Ex-Employee|0.00", label: "Ex-Employee" },
        { value: "Friends of NewTV - Business Organization|75.00", label: "Friends of NewTV - Business Organization" },
        { value: "Friends of NewTV - Family|35.00", label: "Friends of NewTV - Family" },
        { value: "Friends of NewTV - Individual|25.00", label: "Friends of NewTV - Individual" },
        { value: "Friends of NewTV - NPO|50.00", label: "Friends of NewTV - NPO" },
        { value: "Government|0.00", label: "Government" },
        { value: "Instructor|0.00", label: "Instructor" },
        { value: "Intern|0.00", label: "Intern" },
        { value: "Membership - Corporate|100.00", label: "Membership - Corporate" },
        { value: "Membership - Educational|100.00", label: "Membership - Educational" },
        { value: "Membership - Family|50.00", label: "Membership - Family" },
        { value: "Membership - Individual|25.00", label: "Membership - Individual" },
        { value: "Non-Profit Organization|100.00", label: "Non-Profit Organization" },
        { value: "Other|0.00", label: "Other" },
        { value: "Staff|0.00", label: "Staff" },

    ]

    public static volunteerList = ['Audio', 'Editing', 'On-Camera Talent', 'Camera', 'Graphics', 'Studio', 'Director', 'Lighting', 'Lectures', 'Sports',
        'Town Meetings', 'News']

    public static mailingList = [
        'Artists',
        'Board',
        'Govt Agency',
        'Members',
        'New Members',
        'NewTV Staff',
        'School Committee',
        'Schools',
        'Staff',
        'Volunteer Categories',
    ]

}