import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { DonationService } from 'src/app/services/donation.service';
import { LocationService } from 'src/app/services/location.service';

@Component({
  selector: 'app-print-donation',
  templateUrl: './print-donation.component.html',
  styleUrls: ['./print-donation.component.scss']
})
export class PrintDonationComponent implements OnInit {
  id;
  donorData;
  locationData;
  donationSettingDate;
  loading;
  constructor(
    private donationService: DonationService,
    private locationService: LocationService,
    private authService: AuthenticationService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.loading = true
    this.id = this.route.snapshot.queryParamMap.get('id');
    this.locationD()
  }

  locationD (){
    this.loading = true
    this.locationService.getLocationById(this.authService.currentUserValue.location._id).subscribe(res => {
      if (res) {
        this.locationData = res.data
        this.donationD()
        this.getDonationSetting()

      }
    }, err => {
      console.log(err)
      this.loading = false
    })
  }

  donationD(){
    this.donationService.getDonation(this.authService.currentUserValue.location._id,this.id).subscribe( res => {
      this.donorData = res.data
      this.loading = false
      setTimeout(() => {
        window.print();
      }, 2500);
    }, err => {
      console.error(err)
      this.loading = false
    })
  }

  getDonationSetting(){
    this.loading = true;
    this.donationService.GetDonationsSetting(this.authService.currentUserValue.loc_id).subscribe( res =>{
      if(res.data !== null){
        this.donationSettingDate = res.data[0]
      }
      this.loading = false;
    }, err => {
      console.log(err)
      this.loading = false;
    })

  }

}
