<div class="container-fluid">
    <div class="row">
        <div class="offset-md-4 col-md-4 col-sm-12 col-xs-12">
            <mat-card class="card-style">
                <mat-toolbar class="tool-bar">
                </mat-toolbar>
                <div class="row">
                    <div class="col-sm-12 text-center">
                        <img class="logo-image" src="../../../../assets/logo-isaac.png" />
                    </div>
                    <div class="col-sm-12">
                        <form class="loginForm" name="resetPassForm" [formGroup]="resetPassForm" (ngSubmit)="f.form.valid && resetPass()" #f="ngForm">
                            <div class="form-group row">
                                <div class="col-sm-12">
                                    <label class="input-label">Password<span>*</span></label>
                                    <mat-form-field class="full-width" appearance="outline">
                                        <input autocomplete="new-password" formControlName="password" type="password" matInput placeholder="Password"
                                            required>
                                        <mat-icon class="icon" matSuffix>vpn_key</mat-icon>
                                        <mat-error *ngIf="resetPassForm.controls['password'].invalid">Password is required
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-sm-12 mt-2">
                                    <label class="input-label">Confirm Password<span>*</span></label>
                                    <mat-form-field class="full-width" appearance="outline">
                                        <input autocomplete="confirm-password" formControlName="confirmPassword" type="password" matInput placeholder="Confirm Password"
                                            required>
                                        <mat-icon class="icon" matSuffix>vpn_key</mat-icon>
                                        <mat-error *ngIf="resetPassForm.controls['confirmPassword'].errors?.required">Confirm password is required
                                        </mat-error>
                                        <mat-error *ngIf="resetPassForm.controls['confirmPassword'].errors?.confirmedValidator">Passsword and Confirm Password didn't match
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-sm-12">
                                    <br />
                                    <button type="submit" class="btn btn-block"
                                        mat-raised-button>Reset Password</button>
                                </div>
                            </div>
                        </form>


                    </div>

                </div>
            </mat-card>

        </div>
    </div>
</div>